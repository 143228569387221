import AmazonPayButtonMgr from 'amazon/AmazonPayButtonMgr';

(function(app, $) {
	var $cache = {},
		fancyBoxTimeout;

	function initCache() {
		$cache = {
			document: $(document),
			window: $(window),
			wishlistBlock: $('.js-wishlistblock'),
			wishlistQuantity: $('.js-wishlist-quantity'),
			pdpProductIDSel: '.js-product_id:first',
			addToStatusSel: '.js-addtostatus',
			wishlistCls: 'b-product_add_wishlist',
			cartCls: 'b-product_add_event'
		};
	}

	function initEvents() {
		$cache.window.on('orientationchange', function(){
			var cartFancyBox = $('.' + $cache.cartCls);
			if(cartFancyBox.length){
				clearTimeout(fancyBoxTimeout);
				fancyBoxTimeout = setTimeout(function(){
					addToStatusCorrection($cache.cartCls);
				}, 500);
			}
		});
		
		$cache.window.on('appleypay.privacy.revealed', function(e, privacyBlock, firstOpened){
			privacyBlock = $(privacyBlock);
			
			if(firstOpened && privacyBlock.closest('.' + $cache.cartCls).length){
				addToStatusCorrection($cache.cartCls);
			}
		});
		
		$cache.document.on('fancy.mobile.correction', function(e, wrapCSS){
			addToStatusCorrection(wrapCSS);
		});
		
		$cache.document.on('product.added wishlist.added', function(e, container, info) {
			var isPopupShown;

			if (app.page.ns === 'checkout' && e.type === 'wishlist') {
				isPopupShown = app.preferences.productCheckoutShowPopup && !$cache.wishlistBlock.length;
			} else {
				isPopupShown = app.preferences.productShowPopup;
				if (e.type === 'product') {
					isPopupShown = !app.preferences.isMiniCartEnabled && app.preferences.productShowPopup;
				}
			}

			if ('added' === e.namespace && isPopupShown) {
				var queryParams = {
					addedTo: 'product' === e.type ? 'cart' : e.type,
					productID: $($cache.pdpProductIDSel).val()
				};
				
				if (typeof info === 'object') {
					queryParams.success = info.success;
					queryParams.errorMessage = info.errorMessage;
				}
				
				var url = app.util.appendParamsToUrl(app.urls.productOnAddTo, queryParams);
				if (url) {
					app.fancybox.open(url, {
						type: 'ajax',
						width: '100%',
						margin: 0,
						padding: 0,
						wrapCSS: e.type === 'wishlist' ? $cache.wishlistCls : $cache.cartCls,
						autoSize: true,
						onReady: function() {
							// workaround to prevent effect when pop-up flashes with 100% width before normal dimensions are set
							this.wrap && this.wrap.css('visibility', 'hidden');
						}, // Before loading
						beforeShow: false, // Cut off function from app.fancybox.js
						afterShow: function() {
							$cache.document.trigger('fancy.mobile.added');
							$cache.document.trigger('fancy.mobile.correction', this.wrapCSS);

							if (app.amazon && app.amazon.pay.enabled) {
								const amazonPayButtons = document.querySelectorAll('.js-amazon-pay-button');

								if (amazonPayButtons.length > 0) {
									for (const amazonPayButton of amazonPayButtons) {
										AmazonPayButtonMgr.render(amazonPayButton, app.amazon.pay, 'Cart');
									}
								}
							}
						},
						afterClose: function() {
							$cache.document.trigger('fancy.mobile.closed');
						}
					});
				}
			}
		});

		$cache.document.on('wishlist.added', function(e, quantity) {
			if (quantity) {
				$cache.wishlistQuantity.text(quantity);
			}
		});
	}
	
	function addToStatusCorrection(wrapCSS){
		var fancyBox = $('.' + wrapCSS),
			addToStatusBox = fancyBox.find($cache.addToStatusSel);
		
		if(wrapCSS === $cache.cartCls && addToStatusBox.length && addToStatusBox.css('position') === 'absolute'){
			var extraPadding = 10,
				fancyBoxHeight = fancyBox.outerHeight(),
				addToStatusBoxHeight = addToStatusBox.height();
			if(addToStatusBoxHeight > fancyBoxHeight){
				addToStatusBox.css('top', parseInt(fancyBoxHeight/2 + (addToStatusBoxHeight - fancyBoxHeight)/2 + extraPadding) + "px");
			}
			else{
				addToStatusBox.css('top', '50%');
			}
		}
	}

	app.components = app.components || {};
	app.components.product = app.components.product || {};
	app.components.product.mobile = {
		init: function() {
			initCache();
			initEvents();
		}
	};
})((window.app = window.app || {}), jQuery);
